const {multSmpNum, fromNum} = require("./../../SMPNum");

const AchievementConstant = require("./../../constants/AchievementIdConstants");
const SMPAchievement = require("./../../achievement-simulation/models/SMPGameplayAchievement");
// const SMPNum = require("@/SMPNum");

class AchievementSimulationService {
     constructor(achievements, balanceBuilder, gamePlayDataService, supportKpiUtils) {
          this.achievements = achievements;
          this.balanceBuilder = balanceBuilder;
          this.gamePlayDataService = gamePlayDataService;
          this.supportKpiUtils = supportKpiUtils;
          this.completedData = [];
     }

     compute (targetGameLevel,itemFilterChosen, heroBaseDps, supportLevelReached, totalGoldOnLevelReach) {
          this.heroBaseDps = heroBaseDps;
          this.supportLevelReached = supportLevelReached;
          this.totalGoldOnLevelReach = totalGoldOnLevelReach;

          let totalReward = 0;
          let totalCompleted = 0;
          let reachedLevel = targetGameLevel;
          this.completedData = [];

          for (const achievement of this.achievements) {
               let shouldCheck = true;
               if (itemFilterChosen.length > 0) {
                    let matches = itemFilterChosen.filter(eFilter => eFilter.value === achievement.id);

                    if (matches.length > 0) {
                         shouldCheck = true;
                    } else {
                         shouldCheck = false;
                    }
               }

               if (shouldCheck) {
                    let result = this.getDiamondMatchingLevelForAchievement(achievement.id, targetGameLevel);
                    //console.log("achievement(" + achievement.name + ", lv" + targetGameLevel + "): " + reward);
                    if(result.reward > 0){
                         if(result.completed.length > 0){
                              result.completed.forEach(ach =>{
                                   let challenge = "";
                                   if(ach.unit === 'SMPNum'){
                                        challenge = ""+ ach.challenge.ToReadableAlphabetV2();
                                   } else {
                                        challenge = ""+ ach.challenge;
                                   }
                                   this.completedData.push({
                                             id: achievement.id,
                                             questTitleCode: achievement.name.replace("%", challenge),
                                             level: ach.levelComplete,
                                             reward: ach.reward
                                   });
                              });
                         }
                         totalCompleted ++;
                         totalReward = totalReward + result.reward;
                    }

                    // completedData.push({

                    // });
               }
          }

          return {
               "reward": totalReward,
               "countCompleted": totalCompleted,
               "median": totalCompleted / targetGameLevel,
               "reachedLevel": reachedLevel,
               "completedData": this.completedData,
          };
     }

     buildAchievementGameplay (achievementList) {
          let list = [];
          achievementList.forEach(a => {
               let achievement = new SMPAchievement(a, this.gamePlayDataService);
               list.push(achievement);
          })
          return list;
     }
     findAchievementMatchingId (achievementList, achievementId) {
          let filtered =  achievementList.filter(e => e.id === achievementId);
          if (filtered.length > 0) {
               return filtered[0];
          }else {
               return null;
          }
     }
     getDiamondMatchingLevelForAchievement(achievementId, level) {


          let gameplayAchievementList = this.buildAchievementGameplay(this.achievements);

          let achievement = this.findAchievementMatchingId(gameplayAchievementList, achievementId);

          let kpiValueForLevel = this.getKPIGameplayFromLevel(level, achievementId);
          if (! achievement) {
               //console.log(level);
               //console.log(achievmentId);
          }

          if (!kpiValueForLevel) return 0;

          let result = achievement.simulateUnlockForChallengeValue(kpiValueForLevel, achievementId);
          return result;
     }
//this.balanceBuilder.getDiamondMatchingLevelForAchievment(achievement.id, targetGameLevel);
     getKPIGameplayFromLevel(level, kpi) {
          let kpiValue = -1;
          switch (kpi) {

               case AchievementConstant.KILL_GHOST:
                    kpiValue = this.kpiComputeTotalGhostAtLevel(level);
                    break;

               case AchievementConstant.KILL_ENNEMY:
                    kpiValue = this.kpiComputeTotalEnemyAtLevel(level);
                    break;

               default:
                    //console.log("Not yet implemented for kpi : " + kpi);
                    //kpiValue = this.balanceBuilder.getKPIGameplayFromLevel(level, kpi);
                    kpiValue = 0;
                    break;

               case AchievementConstant.COLLECT_GOLD:
                    kpiValue = this.kpiComputeCollectGold(level);
                    break;

               case AchievementConstant.UNLOCK_SUPPORT:
                    kpiValue = this.kpiComputeUnlockSupports(level);
                    break;

               case AchievementConstant.UNLOCK_HEROES:
                    kpiValue = this.kpiComputeUnlockedHero(level);
                    break;

               case AchievementConstant.REACH_HEROES_DPS :
                    kpiValue = this.kpiComputeReachHeroDps();
                    break;

               case AchievementConstant.KILL_N_BOSSES:
                    kpiValue = this.kpiComputeTotalKillBossAt(level);
                    break;

               case AchievementConstant.TAP_N_TIMES:
                    kpiValue = this.kpiComputeTapNTime(level);
                    break;

               case AchievementConstant.REACH_SUPPORT_N_LEVEL:
                    kpiValue = this.kpiComputeReachSupportLevel(level);
                    break;

               case AchievementConstant.KILL_MINIBOSS:
                    kpiValue = this.kpiComputeTotalMiniBossFarming(level);
                    break;

               case AchievementConstant.FAIRY_PRESENT:
                    kpiValue = this.kpiComputeFairyPresent(level);
                    break;

               case AchievementConstant.REACH_STAGE:
                    kpiValue = this.kpiComputeReachStage(level);
                    break;

               case AchievementConstant.REACH_FEAR_BUNNY_EARS:
                    kpiValue = this.kpiComputeReachFearBunny(level);
                    break;

               case AchievementConstant.GET_CRITICAL_HIT:
                    kpiValue = this.kpiComputeReachCriticalHit(level);
                    break;
               //
               // case AchievmentConstant.USE_THUNDER:
               //      kpiValue = this.kpiComputeUseThunderAttack(level);
               //      break;
               //
               //
               // case AchievmentConstant.USE_PERK_DOOM:
               //      kpiValue = this.kpiComputeUseDoom(level);
               //      break;
               //
               // case AchievmentConstant.USE_GIVE_ME_CASH:
               //      kpiValue = this.kpiComputeGiveMeCash(level);
               //      break;
               //
               // case AchievmentConstant.USE_POWER_OF_HOLDING:
               //      kpiValue = this.kpiComputeGiveMeCash(level);
               //      break;
               //
               // case AchievmentConstant.USE_MANA_PORTION:
               //      kpiValue = this.kpiComputeGiveMeCash(level);
               //      break;
          }
          return kpiValue;
     }

     kpiComputeTotalGhostAtLevel(level) {
          return level * 9;
     }
     kpiComputeTotalEnemyAtLevel(level) {
          return level * 10;
     }
     kpiComputeCollectGold() {
          /*old
          let totalGold = this.balanceKpiUtils.computeSumOfGoldForLevel(level);
          return totalGold;*/
          if(!this.totalGoldOnLevelReach){
               return fromNum(0);
          }

          return this.totalGoldOnLevelReach;
     }
     kpiComputeUnlockSupports(level) {
          let totalGold = this.kpiComputeCollectGold();
          totalGold = multSmpNum(totalGold, fromNum(0.5));
          let possibleUnlock = this.supportKpiUtils.GetNumberOfSupportUnlockable(totalGold, level, 0, 3);
          return possibleUnlock;
     }

     kpiComputeUnlockedHero(level) {
          let kpi = Math.floor((level+10) * 1.0 / 10);//Math.min(8, Math.floor(level *1.0 / 10));
          return kpi;
     }
     kpiComputeReachHeroDps(){
          if (!this.heroBaseDps){
               return fromNum(0);
          }
          return this.heroBaseDps;
     }
     kpiComputeTotalKillBossAt(level) {
          //this boss, is battle boss
          let counter = Math.floor(level / 2.0);
          if(level % 2.0 !== 0){
               counter += 1;
          }
          return counter;
     }
     kpiComputeTapNTime(level){
          let ghostPerLevel = 9;
          let ghostFarm = ghostPerLevel / 2;
          let totalKills =(ghostFarm + ghostPerLevel) * level;
          let tapPerGhost = 20;
          let totalTaps = tapPerGhost * totalKills;
          return totalTaps;
     }
     kpiComputeReachSupportLevel(){
          if (!this.supportLevelReached) return 0;
          return this.supportLevelReached;
     }

     kpiComputeReachStage(level){
          return level;
     }
     kpiComputeTotalMiniBossFarming(level) {
          let counter = Math.floor(level / 2.0);
          return counter;
     }

     kpiComputeFairyPresent(level){
          let counter = Math.floor(level / 7);//7 stage will appear one bird
          return counter;
     }

     kpiComputeReachFearBunny(level){
          let counter = Math.floor(level / 235);//235 stage will unlock 1 level
          return counter;
     }

     kpiComputeReachCriticalHit(level){
          let tapCounter = this.kpiComputeTapNTime(level);
          let criticalPerTapRatio = 0.002;
          return tapCounter * criticalPerTapRatio;
     }
}

module.exports = AchievementSimulationService;